[data-rmiz-wrap='visible'],
[data-rmiz-wrap='hidden'] {
    position: relative;
    display: inline-flex;
    align-items: flex-start;
}
[data-rmiz-wrap='hidden'] {
    visibility: hidden;
}
[data-rmiz-overlay] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgb(19, 19, 19, 0.8);
    /* transition-property: background-color; */
}

[data-rmiz-overlay] img {
    object-fit: contain;
}

[data-rmiz-btn-close] {
    position: absolute;
    top: 0;
    background-color: transparent;
    background: #000;
    right: 0;
    width: 40px;
    height: 40px;

    /* reset styles */
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    appearance: none;
}

[data-rmiz-btn-open] {
    position: absolute;
    top: 4px;
    background-color: rgba(124, 124, 124, 0.5);
    border-radius: 6px;
    overflow: hidden;
    right: 4px;
    width: 32px;
    height: 32px;

    /* reset styles */
    margin: 0;
    padding: 0;
    border: none;
    cursor: zoom-in;
    visibility: hidden;
}

[data-rmiz-wrap='visible']:hover [data-rmiz-btn-open] {
    visibility: visible;
}
[data-rmiz-btn-close] {
    cursor: zoom-out;
    z-index: 9999;
}
[data-rmiz-modal-content] {
    position: absolute;
    transition-property: transform;
    transform-origin: center center;
}
